.wrapperabout {
  display: flex;
  justify-content: center;
}

.container {
  width: 100%;
  height: 300px;
  position: absolute;
  top: 0;
}
.container img {
  width: 100%;
  height: 407px;
}
.heading1 {
  position: relative;
  color: #2f4d4b;
  
  font-size: 67px;
  font-weight: bold;
  word-spacing: 3px;
  border-bottom: 3px solid   #2f4d4b;
    border-top: 3px solid #2f4d4b ;
  top: 100px;
}
.containerabdes {
  display: flex;
  justify-content: center;
}
.abcontWrapper {
  display: flex;
  flex-direction: column;
  color: #391710;
  margin-top: 300px;

  background-color: #f6f3ea;
  height: auto;
  width: 95%;
 
 
}
.cont1 {
  padding: 30px;
}
.wrap1 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  gap: 20px;
}
.abheading {
  color: #391710;
  font-size: 40px;
  font-weight: bold;
  word-spacing: 3px;

  display: inline;
}
.abimgwrap {
  height: 493px;
  width: 753px;
  
  margin-top: 22px;
}
.abparagraph {
  font-size: 25px;
  line-height: 35px;
  text-align: center;
  word-spacing: 4px;
  font-weight: 400;
  padding: 20px;
  color: #391710;
  
}

.abparagraph1 {
  font-size: 25px;
  line-height: 38px;
  text-align: center;
  word-spacing: 1px;

  padding: 20px;
  
  color: #391710;
}
@media screen and (max-width: 1250px) {
  .wrapperabout {
    display: flex;
    justify-content: center;
  }

  .container {
    width: 100%;
    height: 300px;
    position: absolute;
    top: -46px;
  }
  .container img {
    width: 100%;
    height: 407px;
  }
  .heading1 {
    position: relative;
    color: #2f4d4b;
    font-size: 60px;
    font-weight: bold;
    word-spacing: 3px;
    border-bottom: 3px solid   #2f4d4b;
    border-top: 3px solid #2f4d4b ;
    top: 90px;
  }
  .containerabdes {
    display: flex;
    justify-content: center;
  }
  .abcontWrapper {
    display: flex;
    flex-direction: column;
    color: #391710;
    margin-top: 300px;
  
    background-color:#f6f3ea;
    height: auto;
    width: 95%;
    
   
  }
  .cont1 {
    padding: 30px;
  }
  .wrap1 {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    padding-top: 40px;
  }
  .abheading {
    color: #391710;
    font-size: 35px;
    font-weight: bold;
    word-spacing: 3px;
   
    display: inline;
  }
  .abimgwrap {
    height: 450px;
    width: 720px;

    
  }
  .abparagraph {
    font-size: 22px;
    line-height: 33px;
    text-align:center;
    word-spacing: 1px;
    
    padding-left: 12px;
    color: #391710;
  }
  .abparagraph1 {
    font-size: 22px;
    line-height: 33px;
    text-align: center;
    word-spacing: 1px;
   
    padding: 12px;

    color: #391710;
  }
}
@media screen and (max-width: 1026px) {
  .wrapperabout {
    display: flex;
    justify-content: center;
  }

  .container {
    width: 100%;
    height: 300px;
    position: absolute;
    top: -46px;
  }
  .container img {
    width: 100%;
    height: 407px;
  }
  .heading1 {
    position: relative;
    color: #2f4d4b;
    font-size: 50px;
    font-weight: bold;
    word-spacing: 3px;
    border-bottom: 3px solid   #2f4d4b;
    border-top: 3px solid #2f4d4b ;
    top: 80px;
  }
  .containerabdes {
    display: flex;
    justify-content: center;
  }
  .abcontWrapper {
    display: flex;
    flex-direction: column;
    color: #391710;
    margin-top: 224px;

    background-color:#f6f3ea;
    height: auto;
    width: 95%;
   
  
  }
  .cont1 {
    padding: 10px;
  }
  .wrap1 {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    padding-top: 20px;
  }
  .abheading {
    color: #391710;
    font-size: 30px;
    font-weight: bold;
    word-spacing: 3px;
   
    display: inline;
  }
  .abimgwrap {
    
      height: 388px;
      width: 551px;
  }

    
 
  .abparagraph {
    font-size: 20px;
    line-height: 30px;
    text-align: center;
    word-spacing: 1px;
   
    padding-left: 12px;
    color: #391710;
  }
  .abparagraph1 {
    font-size: 20px;
    line-height: 28px;
    text-align: center;
    word-spacing: 1px;
  
    padding-right: 12px;

    color: #391710;
  }
}
@media screen and (max-width: 950px) {
  .wrapperabout {
    display: flex;
    justify-content: center;
  }

  .container {
    width: 100%;
    height: 350px;
    position: absolute;
    top: -46px;
  }
  .container img {
    width: 100%;
    height: 350px;
  }
  .heading1 {
    position: relative;
    color:  #2f4d4b;
    font-size: 29px;
    
    word-spacing: 3px;
    height: 50px;
    width: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
  
   
    border-bottom: 3px solid   #2f4d4b;
    border-top: 3px solid #2f4d4b ;
    top: 80px;
    opacity: 1;
    animation: flow ease 3s;
  }
  @keyframes flow {
    0% {
       opacity: 0;
    }
    50%{
       opacity: 0.5;
    }
    100%{
      opacity:1;
    }
  }
  .containerabdes {
    display: flex;
    justify-content: center;
  }
  .abcontWrapper {
    display: flex;
    flex-direction: column;
    color: #391710;
    margin-top: 192px;

    background-color: #f6f3ea;
    height: auto;
    width: 95%;
   
   
  }
  .cont1 {
    padding: 20px;
  }
  .wrap1 {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    padding-top: 20px;
  }
  .abheading {
    color: #391710;
    font-size: 25px;
    font-weight: bold;
    word-spacing: 3px;
   
    display: inline;
  }
  .abimgwrap {
    height: 324px;
    width: 447px;
}
  .abparagraph {
    font-size: 20px;
    line-height: 33px;
    text-align:center;
    word-spacing: 1px;
    
    padding-top: 10px;
    color: #391710;
  }
  .abparagraph1 {
    font-size: 20px;
    line-height: 33px;
    text-align:center;
    word-spacing: 1px;
   
    padding-top: 6px;

    color: #391710;
  }
}
@media screen and (max-width: 600px) {
  .wrapperabout {
    display: flex;
    justify-content: center;
  }

  .container {
    width: 100%;
    height: 300px;
    position: absolute;
    top: -46px;
  }
  .container img {
    width: 100%;
    height:317px;
  }
  .heading1 {
    position: relative;
    color:  #2f4d4b;
    font-size: 29px;
    
    word-spacing: 3px;
    height: 50px;
    width: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
  
   
    border-bottom: 3px solid   #2f4d4b;
    border-top: 3px solid #2f4d4b ;
    top: 80px;
    opacity: 1;
    animation: flow ease 3s;
  }
  @keyframes flow {
    0% {
       opacity: 0;
    }
    50%{
       opacity: 0.5;
    }
    100%{
      opacity:1;
    }
  }
  .containerabdes {
    display: flex;
    justify-content: center;
  }
  .abcontWrapper {
    display: flex;
    flex-direction: column;
    color: #391710;
    margin-top: 168px;

    background-color:#f6f3ea;
    height: auto;
    width: 95%;
   
   
  }
  .cont1 {
    padding: 20px;
  }
  .wrap1 {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    padding-top: 20px;
  }
  .abheading {
    color: #391710;
    font-size: 22px;
    font-weight: bold;
    word-spacing: 3px;
   
    display: inline;
  }
  .abimgwrap {
    height: 300px;
    width: 420px;

    
  }
  .abparagraph {
    font-size: 18px;
    line-height: 30px;
    text-align:center;
    word-spacing: 1px;
   
    padding-top: 30px;
    color: #391710;
  }
  .abparagraph1 {
    font-size: 18px;
    line-height: 30px;
    text-align:center;
    word-spacing: 1px;
   
    padding-top: 6px;

    color: #391710;
  }
}
@media screen and (max-width: 430px) {
    .wrapperabout {
      display: flex;
      justify-content: center;
    }
  
    .container {
      width: 100%;
      height: 300px;
      position: absolute;
      top: -46px;
    }
    .container img {
      width: 100%;
      height: 317px;
    }
    .heading1 {
      position: relative;
      color:  #2f4d4b;
      font-size: 27px;
      
      word-spacing: 3px;
      height: 50px;
      width: 150px;
      display: flex;
      align-items: center;
      justify-content: center;
    
     
      border-bottom: 3px solid   #2f4d4b;
      border-top: 3px solid #2f4d4b ;
      top: 35px;
      opacity: 1;
      animation: flow ease 3s;
    }
    @keyframes flow {
      0% {
         opacity: 0;
      }
      50%{
         opacity: 0.5;
      }
      100%{
        opacity:1;
      }
    }
    .containerabdes {
      display: flex;
      justify-content: center;
    }
    .abcontWrapper {
      display: flex;
      flex-direction: column;
      color: #391710;
      margin-top: 159px;
  
      background-color:#f6f3ea;
      height: auto;
      width: 95%;
    
    }
    .cont1 {
      padding: 10px;
    }
    .wrap1 {
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      align-items: center;
     
      padding: 10px;
    }
    .abheading {
      color: #391710;
      font-size: 20px;
      font-weight: bold;
      word-spacing: 3px;
    
      display: inline;
    }
    .abimgwrap {
      height: 201px;
      width: 275px;
  }
    .abparagraph {
      font-size: 15px;
      line-height: 23px;
      text-align: center;
      word-spacing: 1px;
      
      padding-top: 10px;
      color: #391710;
    }
    .abparagraph1 {
      font-size:15px;
      line-height: 23px;
      text-align: center;
      word-spacing: 1px;
    
      padding-top: 6px;
  
      color: #391710;
    }
  }
  