.footer {
  margin-top: 50px;
}
.footerWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  background-color: #f7f2ed;
  color: #2f4d4b;
  font-weight: 500;
  width: 100%;
}
.footerWrapper .img {
  height: 288px;
  width: 301px;
}

.infof {
  width: 435px;
  display: flex;
  flex-direction: column;

  text-align: left;
  justify-content: right;
  font-family: "Font Awesome 5 Brands";
}
.Coninfof {
  font-size: 20px;
  text-align-last: left;
  padding-top: 15px;
}
.linkWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: left;
}
.headinglink {
  font-size: 24px;
  font-weight: 900;
  font-family: "Font Awesome 5 Brands";
}
.link Link {
  text-decoration: none;
}

.link li {
  list-style: none;
  font-size: 20px;
  text-decoration: none;
  list-style: none;
  font-family: "Font Awesome 5 Brands";
  padding: 10px 0px;
  color: #2f4d4b;
  font-weight: 800;
}

.iconf {
  display: flex;

  align-items: center;
  gap: 20px;
  padding: 20px 0px;
}
.bottonfoot {
  background-color: #391710;
  color: white;
  height: 30px;
  display: flex;
  align-items: center;
  font-size: 15px;
  font-family: "Cinzel", serif;
  justify-content: center;
}

@media screen and (max-width: 900px) {
  .footer {
    margin-top: 50px;
  }
  .footerWrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: #f7f2ed;
    color: #2f4d4b;
    font-weight: 500;
    width: 100%;
  }
  .footerWrapper .img {
    height: 230px;
    width: 230px;
  }

  .infof {
    width: 345px;
    display: flex;
    flex-direction: column;

    text-align: left;
    justify-content: right;
  }
  .Coninfof {
    font-size: 14px;
    text-align-last: left;
    padding-top: 15px;
  }
  .linkWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: left;
  }
  .headinglink {
    font-size: 16px;
  }
  .link Link {
    text-decoration: none;
  }

  .link li {
    list-style: none;
    font-size: 13px;
    text-decoration: none;
    list-style: none;

    padding: 10px 0px;
  }

  .iconf {
    display: flex;

    align-items: center;
    gap: 20px;
    padding: 20px 0px;
  }
  .bottonfoot {
    background-color: #391710;
    color: white;
    height: 30px;
    display: flex;
    align-items: center;
    font-size: 13px;
    font-family: "Cinzel", serif;
    justify-content: center;
  }
}
@media screen and (max-width: 680px) {
  .footer {
    margin-top: 50px;
  }
  .footerWrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    background-color: #f7f2ed;
    color: #2f4d4b;
    width: 100%;
  }
  .footerWrapper .img {
    height: 230px;
    width: 230px;
  }

  .infof {
    width: 345px;
    display: flex;
    flex-direction: column;

    text-align: left;
    justify-content: right;
  }
  .Coninfof {
    font-size: 14px;
    text-align-last: left;
    padding-top: 15px;
  }
  .linkWrapper {
    display: none;
  }

  .Coninfof span {
    font-size: 13px;
    /* padding: 0px 20px; */

    line-height: 2px;
  }
  .iconf {
    display: flex;
    justify-content: left;

    gap: 20px;
    padding: 20px 0px;
  }
  .bottonfoot {
    background-color: #391710;
    color: white;
    height: 30px;
    display: flex;
    align-items: center;
    font-size: 13px;

    justify-content: center;
  }
}
@media screen and (max-width: 480px) {
  .footer {
    margin-top: 50px;
  }
  .footerWrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    background-color: #f7f2ed;
    color: #2f4d4b;
    width: 100%;
  }
  .footerWrapper .img {
    height: 165px;
    width: 165px;
  }

  .infof {
    width: 345px;
    display: flex;
    flex-direction: column;

    text-align: left;
    justify-content: right;
  }
  .Coninfof {
    font-size: 13px;

    padding-top: 15px;
  }
  .linkWrapper {
    display: none;
  }

  .iconf {
    display: flex;
    justify-content: left;

    gap: 20px;
    padding: 20px 0px;
  }
  .iconf img {
    height: 32px;
    width: 32px;
  }
  .bottonfoot {
    background-color: #391710;
    color: white;
    height: 25px;
    display: flex;
    align-items: center;
    font-size: 10px;

    justify-content: center;
  }
}
@media screen and (max-width: 380px) {
  .footer {
    margin-top: 50px;
  }
  .footerWrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    background-color: #f7f2ed;
    color: #2f4d4b;
    width: 100%;
  }
  .footerWrapper .img {
    height: 135px;
    width: 135px;
  }

  .infof {
    width: 345px;
    display: flex;
    flex-direction: column;

    text-align: left;
    justify-content: right;
  }
  .Coninfof {
    font-size: 9px;
    text-align-last: left;
    padding-top: 15px;
  }
  .linkWrapper {
    display: none;
  }

  .Coninfof span {
    font-size: 8px;

    line-height: 2px;
  }
  .iconf {
    display: flex;
    justify-content: left;

    gap: 20px;
    padding: 20px 0px;
  }
  .iconf img {
    height: 32px;
    width: 32px;
  }
  .bottonfoot {
    background-color: #391710;
    color: white;
    height: 25px;
    display: flex;
    align-items: center;
    font-size: 10px;

    justify-content: center;
  }
}
