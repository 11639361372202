@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@700&family=Roboto+Flex:opsz,wght@8..144,100..1000&family=Tangerine:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Cinzel:wght@400..900&family=Raleway:wght@700&family=Roboto+Flex:opsz,wght@8..144,100..1000&family=Tangerine:wght@400;700&display=swap');
body {
  margin: 0;
  font-family: "Roboto Flex", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color:white;
}

code {
  font-family:  "Tangerine";
}
