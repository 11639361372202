.about {
  display: flex;
  justify-content: center;
  padding: 40px 0px;
  margin-top: 100px;
}
.aboutWrapper {
  height: auto;
  width: 90%;
  background-color: #f6f3ea;

  padding: 10px;
}
.heading {
  padding: 30px;
}
.heading h1 {
  color: #391710;
  font-size: 33px;
  font-weight: bold;
  word-spacing: 3px;
  
  font-family: "Cinzel", serif;
  display: inline;
}
.AbWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.AbWrapper img {
  height: 413px;
  width: 661px;
  margin-top: -121px;
}
.paragraph {
  font-size: 21px;
  line-height: 40px;
  text-align-last: left;
  word-spacing: 4px;
  font-weight: 300;
  padding-top: 10px;
  color: #391710;
  padding: 10px;
}
[data-aos="new-animation"] {
  opacity: 0;
  transition-property: transform, opacity;

  &.aos-animate {
    opacity: 1.3;
  }
}
.abbtn {
  padding: 20px;
}
.abbtn button {
  padding: 10px 40px;
  background-color: #2f4d4b;
  color: white;
  font-family: "Cinzel", serif;
  font-size: 21px;
  border-radius: 5px;

  word-spacing: 1px;
}
.abbtn button:hover {
  letter-spacing: 2px;
}
@media screen and (max-width: 1300px) {
  .about {
    display: flex;
    justify-content: center;
    padding: 40px 0px;
    margin-top: 100px;
  }
  .aboutWrapper {
    height: auto;
    width: 90%;
    background-color: #f6f3ea;
    padding: 10px;
}
  .heading {
    padding: 30px;
  }
  .heading h1 {
    color: #391710;
    font-size: 31px;
    font-weight: bold;
    word-spacing: 3px;
    
    font-family: "Cinzel", serif;
    display: inline;
  }
  .AbWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .AbWrapper img {
    height: 432px;
    width: 652px;
    margin-top: -121px;
}
  .paragraph {
    font-size: 21px;
    line-height: 40px;
    text-align-last: left;
    word-spacing: 4px;
    font-weight: 300;
    padding-top: 10px;
    color: #391710;
    padding: 10px;
  }
  [data-aos="new-animation"] {
    opacity: 0;
    transition-property: transform, opacity;
  
    &.aos-animate {
      opacity: 1.3;
    }
  }
  .abbtn {
    padding: 20px;
  }
  .abbtn button {
    padding: 10px 40px;
    background-color: #2f4d4b;
    color: white;
    font-family: "Cinzel", serif;
    font-size: 21px;
    border-radius: 5px;
  
    word-spacing: 1px;
  }

  .abbtn button:hover {
    letter-spacing: 2px;
  }
}
@media screen and (max-width: 1100px) {
  .about {
    display: flex;
    justify-content: center;
    padding: 40px 0px;
  }
  .aboutWrapper {
    height: auto;
    width: 90%;
    background-color: #f6f3ea;
  
    padding: 10px;
  }
  .heading {
    padding: 30px;
  }
  .heading h1 {
    color: #391710;
    font-size: 30px;
    font-weight: bold;
    word-spacing: 3px;
   
    font-family: "Cinzel", serif;
    display: inline;
  }
  .AbWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .AbWrapper img {
    height: 399px;
    width: 635px;
    margin-top: -121px;
}
  .paragraph {
    font-size: 21px;
    line-height: 40px;
    text-align-last: left;
    word-spacing: 4px;
    font-weight: 300;
    padding-top: 10px;
    color: #391710;
    padding: 10px;
  }
  [data-aos="new-animation"] {
    opacity: 0;
    transition-property: transform, opacity;
  
    &.aos-animate {
      opacity: 1.3;
    }
  }
  .abbtn {
    padding: 20px;
  }
  .abbtn button {
    padding: 10px 40px;
    background-color: #2f4d4b;
    color: white;
    font-family: "Cinzel", serif;
    font-size: 21px;
    border-radius: 5px;
  
    word-spacing: 1px;
  }

  .abbtn button:hover {
    letter-spacing: 2px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1000px) {
  .about {
    display: flex;
    justify-content: center;
    padding: 40px 0px;
  }
  .aboutWrapper {
    height: auto;
    width: 90%;
    background-color: #f6f3ea;
  
    padding: 10px;
  }
  .heading {
    padding: 30px;
  }
  .heading h1 {
    color: #391710;
    font-size: 30px;
    font-weight: bold;
    word-spacing: 3px;
 
    font-family: "Cinzel", serif;
    display: inline;
  }
  .AbWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .AbWrapper img {
    height: 368px;
    width: 570px;
    margin-top: -121px;
}
  .paragraph {
    font-size: 21px;
    line-height: 40px;
    text-align-last: left;
    word-spacing: 4px;
    font-weight: 300;
    padding-top: 10px;
    color: #391710;
    padding: 10px;
  }
  [data-aos="new-animation"] {
    opacity: 0;
    transition-property: transform, opacity;
  
    &.aos-animate {
      opacity: 1.3;
    }
  }
  .abbtn {
    padding: 20px;
  }
  .abbtn button {
    padding: 10px 40px;
    background-color: #2f4d4b;
    color: white;
    font-family: "Cinzel", serif;
    font-size: 21px;
    border-radius: 5px;
  
    word-spacing: 1px;
  }

  .abbtn button:hover {
    letter-spacing: 2px;
  }
}
@media screen and (min-width: 630px) and (max-width: 768px) {
  .about {
    display: flex;
    justify-content: center;
    padding: 40px 0px;
  }
  .aboutWrapper {
    height: auto;
    width: 90%;
    background-color:#f6f3ea;
  
    padding: 10px;
  }
  .heading {
    padding: 30px;
  }
  .heading h1 {
    color: #391710;
    font-size: 27px;
    font-weight: bold;
    word-spacing: 3px;
 
    font-family: "Cinzel", serif;
    display: inline;
  }
  .AbWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .AbWrapper img {
    height: 302px;
    width: 451px;
    margin-top: -105px;
}
  .paragraph {
    font-size: 21px;
    line-height: 40px;
    text-align-last: left;
    word-spacing: 4px;
    font-weight: 300;
    padding-top: 10px;
    color: #391710;
    padding: 10px;
  }
  [data-aos="new-animation"] {
    opacity: 0;
    transition-property: transform, opacity;
  
    &.aos-animate {
      opacity: 1.3;
    }
  }
  .abbtn {
    padding: 20px;
  }
  .abbtn button {
    padding: 10px 40px;
    background-color: #2f4d4b;
    color: white;
    font-family: "Cinzel", serif;
    font-size: 21px;
    border-radius: 5px;
  
    word-spacing: 1px;
  }

  .abbtn button:hover {
    letter-spacing: 2px;
  }
}
@media screen and (min-width: 550px) and (max-width: 630px) {
  .about {
    display: flex;
    justify-content: center;
    padding: 40px 0px;
  }
  .aboutWrapper {
    height: auto;
    width: 80%;
    background-color: #f6f3ea;
    padding: 10px;
}
  .heading {
    padding: 20px;
  }
  .heading h1 {
    color: #391710;
    font-size: 24px;
    font-weight: bold;
    word-spacing: 3px;
   
    font-family: "Cinzel", serif;
    display: inline;
}
  .AbWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .AbWrapper img {
    height: 274px;
    width: 402px;
    margin-top: -127px;
}
.paragraph {
  font-size: 17px;
  line-height: 25px;
  text-align-last: left;
  word-spacing: 4px;
  font-weight: 300;
  padding-top: 10px;
  color: #391710;
  padding: 10px;
}
  [data-aos="new-animation"] {
    opacity: 0;
    transition-property: transform, opacity;
  
    &.aos-animate {
      opacity: 1.3;
    }
  }
  .abbtn {
    padding: 20px;
  }
  .abbtn button {
    padding: 10px 40px;
    background-color: #2f4d4b;
    color: white;
    font-family: "Cinzel", serif;
    font-size: 16px;
    border-radius: 5px;
  
    word-spacing: 1px;
  }

  .abbtn button:hover {
    letter-spacing: 2px;
  }
}
@media screen and  (max-width: 550px) {
  .about {
    display: flex;
    justify-content: center;
    padding: 40px 0px;
  }
  .aboutWrapper {
    height: auto;
    width: 80%;
    background-color:#f6f3ea;
    padding: 10px;
}
  .heading {
    padding: 15px;
  }
  .heading h1 {
    color: #391710;
    font-size: 20px;
    font-weight: bold;
    word-spacing: 3px;
 
    font-family: "Cinzel", serif;
    display: inline;
}
  .AbWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .AbWrapper img {
    height: 157px;
    width: 230px;
    margin-top: -127px;
}
.paragraph {
  font-size: 15px;
  line-height: 27px;
  text-align-last: center;
  word-spacing: 4px;
  font-weight: 300;
  /* padding-top: 10px; */
  color: #391710;
  padding: 10px;
}
  [data-aos="new-animation"] {
    opacity: 0;
    transition-property: transform, opacity;
  
    &.aos-animate {
      opacity: 1.3;
    }
  }
  .abbtn {
    padding: 20px;
  }
  .abbtn button {
    padding: 10px 40px;
    background-color: #2f4d4b;
    color: white;
    font-family: "Cinzel", serif;
    font-size: 16px;
    border-radius: 5px;
  
    word-spacing: 1px;
  }

  .abbtn button:hover {
    letter-spacing: 2px;
  }
}
