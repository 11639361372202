.Facility {
  display: flex;
  justify-content: center;
  margin-top: 100px;
}
.fcWrapper {
  height: auto;
  width: 90%;
  background-color: #f6f3ea;
}
.fcheading {
  padding: 30px;
}
.fcheading h1 {
  color: #391710;
  font-size: 33px;
  font-weight: bold;
  word-spacing: 3px;

  font-family: "Cinzel", serif;
  display: inline;
}
.facilityWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.facilityWrapper img {
  height: 448px;
  width: 724px;
  margin-top: -127px;
}
.fcparagraph {
  font-size: 21px;
  line-height: 40px;
  text-align-last: left;
  word-spacing: 3px;
  font-weight: 300;
  padding: 20px;
  color: #391710;
}
[data-aos="new-animation"] {
  opacity: 0;
  transition-property: transform, opacity;

  &.aos-animate {
    opacity: 1.3;
  }
}
.fcbtn {
  padding: 20px;
}
.fcbtn button {
  padding: 10px 40px;
  background-color: #2f4d4b;
  color: white;
  font-family: "Cinzel", serif;
  font-size: 21px;
  border-radius: 5px;

  word-spacing: 1px;
}

@media screen and (max-width: 1300px) {
  .fcWrapper {
    height: auto;
    width: 90%;
    background-color: #f6f3ea;
    padding: 10px;
  }
  .fcheading {
    padding: 30px;
  }
  .fcheading h1 {
    color: #391710;
    font-size: 31px;
    font-weight: bold;
    word-spacing: 3px;
 
    font-family: "Cinzel", serif;
    display: inline;
  }
  
  .facilityWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .facilityWrapper img {
    height: 448px;
    width: 724px;
    margin-top: -127px;

    
  }
  .fcparagraph {
    font-size: 21px;
    line-height: 40px;
    text-align-last: left;
    word-spacing: 4px;
    font-weight: 300;
    padding-top: 10px;
    color: #391710;
    padding: 10px;
  }
  .fcbtn {
    padding: 20px;
  }
  .fcbtn button {
    padding: 10px 40px;
    background-color: #2f4d4b;
    color: white;
    font-family: "Cinzel", serif;
    font-size: 21px;
    border-radius: 5px;
  
    word-spacing: 1px;
  }
  .abbtn button:hover {
    letter-spacing: 2px;
  }
}
@media screen and (max-width: 1100px) {
  .fcWrapper {
    height: auto;
    width: 90%;
    background-color: #f6f3ea;
  
    padding: 10px;
  }
  .fcheading {
    padding: 30px;
  }
  .fcheading h1 {
    color: #391710;
    font-size: 30px;
    font-weight: bold;
    word-spacing: 3px;
   
    font-family: "Cinzel", serif;
    display: inline;
  }
  .facilityWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .facilityWrapper img {
    height: 399px;
    width: 635px;
    margin-top: -121px;
  }
  .fcparagraph {
    font-size: 21px;
    line-height: 40px;
    text-align-last: left;
    word-spacing: 4px;
    font-weight: 300;
    padding-top: 10px;
    color: #391710;
    padding: 10px;
  }
  .fcbtn {
    padding: 20px;
  }
  .fcbtn button {
    padding: 10px 40px;
    background-color: #2f4d4b;
    color: white;
    font-family: "Cinzel", serif;
    font-size: 21px;
    border-radius: 5px;
  
    word-spacing: 1px;
  }

  .fcbtn button:hover {
    letter-spacing: 2px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1000px) {
  .fcWrapper {
    height: auto;
    width: 90%;
    background-color: #f6f3ea;
  
    padding: 10px;
  }
  .fcheading {
    padding: 30px;
  }
  .fcheading h1 {
    color: #391710;
    font-size: 30px;
    font-weight: bold;
    word-spacing: 3px;
   
    font-family: "Cinzel", serif;
    display: inline;
  }
  .facilityWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .facilityWrapper img {
    height: 368px;
    width: 570px;
    margin-top: -121px;
  }
  .fcparagraph {
    font-size: 21px;
    line-height: 40px;
    text-align-last: left;
    word-spacing: 4px;
    font-weight: 300;
    padding-top: 20px;
    color: #391710;
  }
  .fcbtn {
    padding: 20px;
  }
  .fcbtn button {
    padding: 10px 40px;
    background-color: #2f4d4b;
    color: white;
    font-family: "Cinzel", serif;
    font-size: 21px;
    border-radius: 5px;
  
    word-spacing: 1px;
  }

  .fcbtn button:hover {
    letter-spacing: 2px;
  }
}
@media screen and (min-width: 630px) and (max-width: 768px) {
  .fcWrapper {
    height: auto;
    width: 90%;
    background-color:#f6f3ea;
  
    padding: 10px;
  }
  .fcheading {
    padding: 30px;
  }
  .fcheading h1 {
    color: #391710;
    font-size: 27px;
    font-weight: bold;
    word-spacing: 3px;
   
    font-family: "Cinzel", serif;
    display: inline;
  }
  .facilityWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .facilityWrapper img {
    height: 302px;
    width: 451px;
    margin-top: -105px;
  }
  .fcparagraph {
    font-size: 21px;
    line-height: 40px;
    text-align-last: left;
    word-spacing: 4px;
    font-weight: 300;
    padding-top: 10px;
    color: #391710;
    padding: 10px;
  }
  .fcbtn {
    padding: 20px;
  }
  .fcbtn button {
    padding: 10px 40px;
    background-color: #2f4d4b;
    color: white;
    font-family: "Cinzel", serif;
    font-size: 21px;
    border-radius: 5px;
  
    word-spacing: 1px;
  }

  .fcbtn button:hover {
    letter-spacing: 2px;
  }
}
@media screen and (min-width: 550px) and (max-width: 630px) {
  .fcWrapper {
    height: auto;
    width: 80%;
    background-color:#f6f3ea;
    padding: 10px;
  }
  .fcheading {
    padding: 20px;
  }
  .fcheading h1 {
    color: #391710;
    font-size: 24px;
    font-weight: bold;
    word-spacing: 3px;
   
    font-family: "Cinzel", serif;
    display: inline;
  }
  .facilityWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .facilityWrapper img {
    height: 274px;
    width: 402px;
    margin-top: -127px;
  }
  .fcparagraph {
    font-size: 17px;
    line-height: 30px;
    text-align-last: left;
    word-spacing: 4px;
    font-weight: 300;
    padding-top: 10px;
    color: #391710;
    padding: 10px;
  }
  .fcbtn {
    padding: 20px;
  }
  .fcbtn button {
    padding: 10px 40px;
    background-color: #2f4d4b;
    color: white;
    font-family: "Cinzel", serif;
    font-size: 16px;
    border-radius: 5px;
  
    word-spacing: 1px;
  }
  .fcbtn button:hover {
    letter-spacing: 2px;
  }
}
@media screen and (max-width: 550px)  {
  .fcWrapper {
    height: auto;
    width: 80%;
    background-color: #f6f3ea;
    padding: 10px;
  }
  .fcheading {
    padding: 15px;
  }
  .fcheading h1 {
    color: #391710;
    font-size: 20px;
    font-weight: bold;
    word-spacing: 3px;
  
    font-family: "Cinzel", serif;
    display: inline;
  }
  .facilityWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .facilityWrapper img {
    height: 147px;
    width: 230px;
    margin-top: -127px;
}
  .fcparagraph {
    font-size: 15px;
  line-height: 27px;
  text-align-last: center;
  word-spacing: 4px;
  font-weight: 300;
  /* padding-top: 10px; */
  color: #391710;
  padding: 10px;
  }
  .fcbtn {
    padding: 20px;
  }
  .fcbtn button {
    padding: 10px 40px;
    background-color: #2f4d4b;
    color: white;
    font-family: "Cinzel", serif;
    font-size: 16px;
    border-radius: 5px;
  
    word-spacing: 1px;
  }
  .fcbtn button:hover {
    letter-spacing: 2px;
  }
}

