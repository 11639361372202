

.mainserv {
  display: flex;
  justify-content: center;
  margin-top: 0px;
}
.serviceWrap {
  height: auto;
  width: 100%;

  background-image: url("../../Assets/bgimage.jpg");
  background-repeat: no-repeat;
  box-shadow: 2px 3px 8px #f8d55689;
}
.mobileimg{
  display: none;
}
.homelogo{
  display: none;
}
.serviceWrap .heading {
  padding: 50px;
}
.serviceWrap .heading h1 {
  color: #391710;
  font-size: 30px;
  font-weight: bold;
  word-spacing: 3px;

  display: inline;
}

.serWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}
.serbtn {
  padding: 70px;
}
.serbtn button {
  padding: 10px;
  background-color: #2f4d4b;
  color: white;
  font-family: "Cinzel", serif;
  font-size: 16px;
  border-radius: 5px;

  word-spacing: 1px;
}
.timepass1 {
  height: 350px;
  width: 300px;
  background-color: rgb(255, 255, 255);
  position: relative;
  overflow: hidden;
  /* border-radius: 50px; */
  box-shadow: 2px 3px 8px rgb(105, 104, 104);
}
.timepass1 img {
  width: 100%;
  height: 100%;
  /* border-radius: 50px; */
  transition: padding 0.9s ease;
}
.timepass1 h1 {
  display: none;
  position: absolute;
  top: 10px;
  left: 0;
  width: 100%;
  height: 120px;
  margin: 0;
  padding: 10px;
  box-sizing: border-box;
  color: #391710;
  text-align: center;
  font-size: 26px;
  font-family: "Roboto Flex", sans-serif;
  /* font-weight: bold; */
}
.timepass1:hover img {
  padding-top: 30%;
}
.timepass1:hover h1 {
  display: block;
}
.Swiper-Wrap {
  width: 100%;
  margin-top: -26px;
}

.swiperSlide {
  background-position: center;
  background-size: cover;
  background-color: rgba(255, 255, 255, 0.882);
  width: 194px;
  height: 288px;
  border-radius: 10px;
}

.swiperSlide .serimg {
  display: block;
  width: 194px;
  height: 266px;
  position: relative;
}
.swiperSlide .sername {
  position: absolute;
  color: white;
  font-size: 20px;
  bottom: 20%;
  left: 25%;
  right: 25%;
  border-top: 1px solid white;
  border-bottom: 1px solid white;
}
.mobile-view {
  display: none;
}

@media screen and (min-width: 849px) and (max-width: 1200px) {
  .serviceWrap .heading h1 {
    color: #391710;
    font-size: 30px;
    font-weight: bold;
    word-spacing: 3px;
    margin-top: 0px;

    display: inline;
  }

  .serWrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }
  .serbtn {
    padding: 30px;
  }
  .serbtn button {
    padding: 10px;
    background-color: #2f4d4b;
    color: white;
    font-family: "Cinzel", serif;
    font-size: 16px;
    border-radius: 5px;

    word-spacing: 1px;
  }
  .timepass1 {
    height: 300px;
    width: 250px;
    background-color: rgb(255, 255, 255);
    position: relative;
    overflow: hidden;
    /* border-radius: 50px; */
    box-shadow: 2px 3px 8px rgb(105, 104, 104);
  }
  .timepass1 img {
    width: 100%;
    height: 100%;
    /* border-radius: 50px; */
    transition: padding 0.9s ease;
  }
  .timepass1 h1 {
    display: none;
    position: absolute;
    top: 10px;
    left: 0;
    width: 100%;
    height: 120px;
    margin: 0;
    padding: 10px;
    box-sizing: border-box;
    color: #391710;
    text-align: center;
    font-size: 23px;
    font-family: "Roboto Flex", sans-serif;
  }
  .timepass1:hover img {
    padding-top: 30%;
  }
  .timepass1:hover h1 {
    display: block;
  }
}
@media screen and (min-width: 768px) and (max-width: 850px) {
  .serviceWrap .heading h1 {
    color: #391710;
    font-size: 27px;
    font-weight: bold;
    word-spacing: 3px;
    margin-top: 0px;

    display: inline;
  }

  .serWrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }
  .serbtn {
    padding: 30px;
  }
  .serbtn button {
    padding: 10px;
    background-color: #2f4d4b;
    color: white;
    font-family: "Cinzel", serif;
    font-size: 16px;
    border-radius: 5px;

    word-spacing: 1px;
  }
  .timepass1 {
    height: 280px;
    width: 230px;
    background-color: rgb(255, 255, 255);
    position: relative;
    overflow: hidden;
    /* border-radius: 50px; */
    box-shadow: 2px 3px 8px rgb(105, 104, 104);
  }
  .timepass1 img {
    width: 100%;
    height: 100%;
    /* border-radius: 50px; */
    transition: padding 0.9s ease;
  }
  .timepass1 h1 {
    display: none;
    position: absolute;
    top: 10px;
    left: 0;
    width: 100%;
    height: 120px;
    margin: 0;
    padding: 10px;
    box-sizing: border-box;
    color: #391710;
    text-align: center;
    font-size: 21px;
    font-family: "Roboto Flex", sans-serif;
  }
  .timepass1:hover img {
    padding-top: 30%;
  }
  .timepass1:hover h1 {
    display: block;
  }
}
@media screen and (min-width: 600px) and (max-width: 768px) {
  .serviceWrap .heading h1 {
    color: #391710;
    font-size: 27px;
    font-weight: bold;
    word-spacing: 3px;
    margin-top: 0px;

    display: inline;
  }

  .serWrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    padding-top: 20px;
  }
  .serbtn {
    padding: 30px;
  }
  .serbtn button {
    padding: 10px;
    background-color: #2f4d4b;
    color: white;
    font-family: "Cinzel", serif;
    font-size: 14px;
    border-radius: 5px;

    word-spacing: 1px;
  }
  .timepass1 {
    height: 244px;
    width: 180px;
    background-color: rgb(255, 255, 255);
    position: relative;
    overflow: hidden;
    /* border-radius: 50px; */
    box-shadow: 2px 3px 8px rgb(105, 104, 104);
  }
  .timepass1 img {
    width: 100%;
    height: 100%;
    /* border-radius: 50px; */
    transition: padding 0.9s ease;
  }
  .timepass1 h1 {
    display: none;
    position: absolute;
    top: 10px;
    left: 0;
    width: 100%;
    height: 120px;
    margin: 0;
    padding: 10px;
    box-sizing: border-box;
    color: #391710;
    text-align: center;
    font-size: 15px;
    font-family: "Roboto Flex", sans-serif;
  }
  .timepass1:hover img {
    padding-top: 30%;
  }
  .timepass1:hover h1 {
    display: block;
  }
}
@media screen and (min-width: 449px) and (max-width: 600px) {
  .mainserv {
    display: flex;
    justify-content: center;
    margin-top: -61px;
  }
  .serviceWrap .heading h1 {
    color: #391710;
    font-size: 24px;
    font-weight: bold;
    word-spacing: 3px;

    display: inline;
  }
  .mobileimg{
    display: block;
    position: relative;
    height: 100vh;
    width: 100%;
    top: -71px;
  }
  .homelogo {
    display: block;
    position: absolute;
    top: -6%;
    height: auto;
    width: 53%;
    left: 27%;
}
  .serWrapper {
    display: none;
    flex-direction: row;
    justify-content: space-around;
  }
  .serbtn {
    padding: 30px;
  }
  .serbtn button {
    padding: 10px;
    background-color: #2f4d4b;
    color: white;
    font-family: "Cinzel", serif;
    font-size: 13px;
    border-radius: 5px;

    word-spacing: 1px;
  }
  #card3,
  #card2 {
    display: none;
  }

  .mobile-view {
    display: block;
  }
}

@media screen and  (max-width: 450px) {
  .mainserv {
    display: flex;
    justify-content: center;
    margin-top: -61px;
  }
  .serviceWrap .heading h1 {
    color: #391710;
    font-size: 20px;
    font-weight: bold;
    word-spacing: 3px;
  
    display: inline;
  }
  .mobileimg{
    display: block;
    position: relative;
    height: 100vh;
    width: 100%;
    top: -71px;
  }
  .homelogo {
    display: block;
    position: absolute;
    height: auto;
    width: 46%;
    top: -3%;
    left: 29%;
    /* right: 20px; */
}

  .serWrapper {
    display: none;
    flex-direction: row;
    justify-content: space-around;
  }
  .serbtn {
    padding: 30px;
  }
  .serbtn button {
    padding: 10px;
    background-color: #2f4d4b;
    color: white;
    font-family: "Cinzel", serif;
    font-size: 13px;
    border-radius: 5px;

    word-spacing: 1px;
  }
  #card3,
  #card2 {
    display: none;
  }

  .mobile-view {
    display: block;
  }
}
/* @media screen and (max-width:326px){
  .homelogo {
    display: block;
    position: absolute;
    height:275px;
    width: 275px;
    top: 11px;
    left: 33px;
    /* right: 20px; */

