.gheading {
  padding: 50px;
  padding-top: 60px;
}
.gheading h1{
  color: #391710;
  font-size: 33px;
  font-weight: bold;
  word-spacing: 3px;
  border-bottom: 3px solid #391710;
  font-family: "Cinzel", serif;
  display: inline;
}

.mySwiper{
  width: 100%;
  height: 100%;
  display: flex;
  gap: 30px;
  justify-content: space-around;
}
.swiper-container {
  width: 100%; 
  background-color: #f0f0f0;
}


.swiperC {
  width: 98%; 
  height: 450px;
  background-color: white; 
}

.swiperC .slideimg {
  width: 98%; 
  height: 100%; 
  object-fit: cover; 
}
@media screen and  (max-width:1024px)  {
  .gheading {
    padding: 50px;
    padding-top: 60px;
  }
  .gheading h1{
    color: #391710;
    font-size: 30px;
    font-weight: bold;
    word-spacing: 3px;
    border-bottom: 3px solid #391710;
    font-family: "Cinzel", serif;
    display: inline;
  }
  
.swiper-container {
  width: 100%; 
  background-color: #f0f0f0;
}


.swiperC {
  width: 98%; 
  height: 400px; 
  background-color: white; 
}

.swiperC.slideimg {
  width: 98%; 
  height: 100%; 
  object-fit: cover; 
}
}
@media screen and  (max-width:768px)  {
  .gheading h1{
    color: #391710;
    font-size: 30px;
    font-weight: bold;
    word-spacing: 3px;
    border-bottom: 3px solid #391710;
    font-family: "Cinzel", serif;
    display: inline;
  }
  
  .swiper-container {
    width: 100%; 
    background-color: #f0f0f0;
  }
  
  
  .swiperC {
    width: 98%; 
    height: 300px;
    background-color: white; 
    
   
  }
  
  .swiperC .slideimg {
    width: 100%; 
    height: 100%; 
    object-fit: cover; 
  }
  }
  @media screen and  (max-width:650px)  {
    .gheading {
  
      padding: 32px;
    }
    .gheading h1{
      color: #391710;
      font-size: 25px;
      font-weight: bold;
      word-spacing: 3px;
      border-bottom: 3px solid #391710;
      font-family: "Cinzel", serif;
      display: inline;
    }
  
    .swiper-container {
      width: 100%; 
      background-color: #f0f0f0;
    }
    
    
    .swiperC {
      width: 98%; 
      height: 185px;
      background-color: white; 
      
     
    }
    
    .swiperC .slideimg {
      width: 50vw; 
      height: 100%; 
      object-fit: cover; 
    }
    }
    @media screen and  (max-width:400px)  {
      .gheading {
      
        padding: 32px;
      }
      .gheading h1{
        color: #391710;
        font-size: 20px;
        font-weight: bold;
        word-spacing: 3px;
        border-bottom: 3px solid #391710;
        font-family: "Cinzel", serif;
        display: inline;
      }
  
      .swiper-container {
        width: 100%; 
        background-color: #f0f0f0;
      }
      
      
      .swiperC {
        width: 98%; 
        height: 170px;
        background-color: white; 
        
       
      }
      .slidevideo{
        width: 60%;
        margin-left: 20px;
      }
      
      .swiperC .slideimg {
        width: 50vw; 
        height: 80%; 
        object-fit: cover; 
      }
      }