.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.mainabb {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.formWrapperr {
  height: 590px;
  width: 500px;
  background-color: white;
  border-radius: 30px;
  box-shadow: 2px 3px 10px rgb(183, 182, 182);
}
.formWrapperr .headingEnn {
  font-size: 40px;
  font-weight: bold;
  color: #391710;
  padding: 10px 0px;
}
.formWrapperr .labell {
  padding: 7px 0px;
}
.formWrapperr .labell {
  font-size: 25px;

  font-weight: bold;
  color: #391710;
}
.formWrapperr input {
  width: 60%;
  padding: 0px 20px;
  height: 30px;
  border-radius: 10px;
}
.formWrapperr select {
  width: 70%;
  padding: 0px 20px;
  height: 35px;
  border-radius: 10px;
}
.formWrapperr .button {
  padding: 30px;
}
.formWrapperr button {
  padding: 10px 50px;
  background-color: #391710;
  border-radius: 5px;
  border: #391710;
  color: white;
}
.butwrap{
  display: flex;
  justify-content: end;
  padding: 30px;
}
.butwrap button{
  padding: 5px;
  border-radius: 3px;
  background-color: white;
  font-size: 15px;
}

.table_Wrapper {
  display: flex;
  justify-content: center;
}
table {
  width: 95%;
  border-collapse: collapse;
  margin-bottom: 20px;
  padding: 10px;
}
th,
td {
  border: 1px solid #000;
  padding: 8px;
  text-align: left;
}
th {
  background-color: #f2f2f2;
}







  * {
    margin: 0;
    padding: 0;
    text-decoration: none;
  }
  .container {
    display: flex;
    position: relative;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
  
  .sidebar {
    background: #391710;
    color: #ffffff;
    width: 80px;
    height: 620px;
    position: absolute;
    top: 60%;
    overflow-y: auto;
    transition: width 0.5s;
  }
  
  .sidebar-open {
    width: 350px;
  }
  
  .top_section {
    display: flex;
    align-items: center;
    padding: 20px 15px;
    justify-content: space-between;
  }
  
  .logo {
    font-size: 30px;
  }
  
  .bars {
    display: flex;
    font-size: 25px;
  }
  
  .link {
    display: flex;
    color: #ffffff;
    padding: 10px 15px;
    gap: 15px;
  }
  
  .link:hover {
    color: darkgray;
  }
  
  .active {
    color: #ffffff;
  }
  
  .icon,
  .link_text {
    font-size: 20px;
  }
  
  main {
    flex: 1;
    overflow-y: auto;
  }
  
  /*  enquiry css */
  .table_Wrapper {
    display: flex;
    justify-content: center;
  }
  table {
    width: 95%;
    border-collapse: collapse;
    margin-bottom: 20px;
    padding: 10px;
  }
  th,
  td {
    border: 1px solid #290101;
    padding: 8px;
    font-family:Arial, Helvetica, sans-serif;
    font-weight: normal;
    font-size: 15px;
    text-align: center;
  }
  th {
    background-color: #2c0303;
    color: white;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: normal;
  }
  @media screen and (max-width: 600px) {
    th,
    td {
      padding: 6px;
      font-size: 12px;
    }
  
    
    /*  Book css */
    .mainabb {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100vh;
    }
  
    .formWrapperr {
      height: 590px;
      width: 500px;
      background-color: white;
      border-radius: 30px;
      box-shadow: 2px 3px 10px rgb(183, 182, 182);
    }
    .formWrapperr .headingEnn {
      font-size: 40px;
      font-weight: bold;
      color: #391710;
      padding: 10px 0px;
    }
    .formWrapperr .labell {
      padding: 7px 0px;
    }
    .formWrapperr .labell {
      font-size: 25px;
  
      font-weight: bold;
      color: #391710;
    }
    .formWrapperr input {
      width: 60%;
      padding: 0px 20px;
      height: 30px;
      border-radius: 10px;
    }
    .formWrapperr select {
      width: 70%;
      padding: 0px 20px;
      height: 35px;
      border-radius: 10px;
    }
    .formWrapperr .button {
      padding: 30px;
    }
    .formWrapperr button {
      padding: 10px 50px;
      background-color: #391710;
      border-radius: 5px;
      border: #391710;
      color: white;
    }
  
    @media screen and (max-width: 450px) {
      th,
      td {
        padding: 4px;
        font-size: 10px;
      }
     .tablehead{
      font-size: 20px;
     }
      
      
      .mainabb {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100vh;
      }
    
      .formWrapperr {
        height: 489px;
        width: 307px;
        background-color: white;
        border-radius: 30px;
        box-shadow: 2px 3px 10px rgb(183, 182, 182);
      }
      .formWrapperr .headingEnn {
        font-size: 32px;
        font-weight: bold;
        color: #391710;
        padding: 7px 0px;
      }
      .formWrapperr .labell {
        padding: 7px 0px;
      }
      .formWrapperr label {
        font-size: 18px;
        font-weight: bold;
        color: #391710;
      }
      .formWrapperr input {
        width: 62%;
        padding: 0px 20px;
        height: 26px;
        border-radius: 3px;
      }
      .formWrapperr select {
        width: 70%;
        padding: 0px 20px;
        height: 35px;
        border-radius: 10px;
      }
      .formWrapperr .buttonn {
        padding: 30px;
      }
      .formWrapperr button {
        padding: 10px 50px;
        background-color: #391710;
        border-radius: 5px;
        border: #391710;
        color: white;
      }
    }
  }
  

.btn{
display: flex;
justify-content: end;
align-items:center;
padding-right: 30px;
}
 .btnToggle{
  padding: 10px;
  border: none;
  border-radius: 5px;
  margin-top: 5px;
  background-color: #391710;
 
  color: white;
 }
 @media screen and (max-width: 375px) {
  th,
  td {
    padding: 2px;
    font-size: 8px;
  }

  
}