

.mainabb {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  
  .formWrapperr {
    height: 350px;
    width: 500px;
    background-color: aqua;
    background-color: white;
    border-radius: 30px;
    box-shadow: 2px 3px 10px rgb(183, 182, 182);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .form{
    display: flex;
    flex-direction: column;
    gap: 30px;
    width: 90%;
    align-items: center;
  }
  .formWrapperr .headingEnn {
    font-size: 40px;
    font-weight: bold;
    color: #391710;
    padding: 10px 0px;
  }
  .formWrapperr .labell {
    padding: 7px 0px;
  }
  .formWrapperr .labell {
    font-size: 25px;
  
    font-weight: bold;
    color: #391710;
  }
  .formWrapperr input {
    width: 70%;
    padding: 10px 20px;
    font-size: 20px;
    height: 30px;
    border-radius: 10px;
  }
  .formWrapperr select {
    width: 70%;
    padding: 0px 20px;
    height: 35px;
    border-radius: 10px;
  }
  .formWrapperr .button {
    padding: 30px;
  }
  .formWrapperr button {
    padding: 10px 70px;
    background-color: #391710;
    border-radius: 5px;
    border: #391710;
    font-size: 20px;
    color: white;
  }
  .butwrap{
    display: flex;
    justify-content: end;
    padding: 30px;
  }
  .butwrap button{
    padding: 5px;
    border-radius: 3px;
    background-color: white;
    font-size: 15px;
  }
  