.CcontWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  color: #391710;
  margin-top: 300px;

  height: 100%;
  width: 100%;
}

.info {
  width: 500px;
  height: 400px;
  background-color:#f6f3ea;
  border-radius: 20px;
  
}
.Coninfo {
  font-size: 25px;
  text-align: left;
  padding: 29px;
  font-weight: bold;
}
.Coninfo span {
  font-size: 23px;
  padding: 0px 20px;
  
}
.icon1 {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 20px;
    
  }
  .icon1 img{
    height: 50px;
    width: 50px;
  }
@media screen and (max-width:1000px){
    .CcontWrapper {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        color: #391710;
        margin-top: 215px;
      
        height: 100%;
        width: 100%;
      }
      
      .info {
        width: 406px;
        height: 361px;
        background-color:#f6f3ea;
      
    }
    .Coninfo {
        font-size: 20px;
        text-align-last: left;
        padding: 29px;
        font-weight: bold;
    }
      .Coninfo span {
        font-size: 19px;
        padding: 0px 15px;
     
      }
      .map{
        width: 406px;
        height: 361px;
      }
}

@media screen and (max-width:800px){
    .CcontWrapper {
        display: flex;
        flex-direction: column;
        gap: 30px;
        align-items: center;
        color: #391710;
        margin-top: 221px;
      
        height: 100%;
        width: 100%;
      }
      
      .info {
        width: 513px;
        height: 338px;
        background-color:#f6f3ea;
       
    }
    .Coninfo {
        font-size: 25px;
        text-align: left;
        align-items: center;
        padding: 20px;
       
    }
      .Coninfo span {
        font-size: 21px;
        padding: 0px 15px;
      
      }
      .map{
        width: 513px;
        height: 338px;
      }
      .icon1 {
        display: flex;
        flex-direction: row;
        justify-content: center;
        gap: 20px;
        
      }
      .icon1 img{
        height: 40px;
        width: 40px;
      }
}

@media screen and (max-width:600px){
    .CcontWrapper {
        display: flex;
        flex-direction: column;
        gap: 30px;
        align-items: center;
        color: #391710;
        margin-top: 179px;
      
        height: 100%;
        width: 100%;
      }
      
      .info {
        width: 310px;
        height: 316px;
        background-color: #f6f3ea;
        border-radius: 30px;
    }
    .Coninfo {
        font-size: 19px;
        text-align: left;
        align-items: center;
        padding: 18px;
       
    }
      .Coninfo span {
        font-size: 14px;
        padding: 0px 15px;
       
      }
      .map{
        width: 310px;
        height: 316px;
      }
      .icon1 {
        display: flex;
        flex-direction: row;
        justify-content: center;
        gap: 20px;
        
      }
      .icon1 img{
        height: 30px;
        width: 30px;
      }
}

