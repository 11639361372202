.headerWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0px 50px;
  background-color: rgba(255, 255, 255, 0.804);
  height: 100px;
  width: auto;
  position: sticky;
  top: 0;
  z-index: 10;
}
.headerWrapper .img {
  height: 200px;
  width: 200px;
}
.headerWrapper .nav ul {
  display: flex;
  flex-direction: row;
  gap: 100px;
}

.headerWrapper .nav ul a {
  text-decoration: none;
  color: #391710;
}
ul a.active {
  color: #9b9a9a;
  font-weight: 500;
  position: relative;
}
.headerWrapper .nav ul li {
  list-style: none;
  font-weight: bold;
  font-size: 25px;
  transition: transform 0.2s;
}
.headerWrapper .nav ul li:hover {
 
 transform: scale(1.2);
}

.headerWrapper .icon {
  display: flex;
  flex-direction: row;
  gap: 50px;
}
.icon img{
  transition: transform 0.2s;
}
.icon img:hover{
  transform: scale(1.2);
}

.menu-icon {
  display: none; 
  cursor: pointer;
}
.menu-icon div {
  width: 25px;
  height: 3px;
  background-color: #391710;
  margin: 6px 0;
  transition: 0.4s;
}

.menu-icon .bar.open:nth-child(1) {
  transform: rotate(-45deg) translate(-5px, 6px);
}

.menu-icon .bar.open:nth-child(2) {
  opacity: 0;
}

.menu-icon .bar.open:nth-child(3) {
  transform: rotate(45deg) translate(-5px, -6px);
}
@media screen and (max-width: 1300px){

  .headerWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  
    background-color: rgba(255, 255, 255, 0.804);
   
    height: 100px;
    width: auto;
  
    position: sticky;
    top: 0;
    z-index: 10;
  }
  .headerWrapper .img {
    height: 200px;
    width: 200px;
  }
  .headerWrapper .nav ul {
    display: flex;
    flex-direction: row;
    gap: 50px;
  }
  
  .headerWrapper .nav ul a {
    text-decoration: none;
    color: #391710;
  }
  .headerWrapper .nav ul li {
    list-style: none;
    font-weight: bold;
    font-size: 22px;
    transition: transform 0.2s;
  }
  .headerWrapper .nav ul li:hover {
    transform: scale(1.2);
   
  }
  
  .headerWrapper .icon {
    display: flex;
    flex-direction: row;
    gap: 20px;
  }
  .menu-icon {
    display: none; 
    cursor: pointer;
  }
  .menu-icon div {
    width: 25px;
    height: 3px;
    background-color: #391710;
    margin: 6px 0;
    transition: 0.4s;
  }
  
  .menu-icon .bar.open:nth-child(1) {
    transform: rotate(-45deg) translate(-5px, 6px);
  }
  
  .menu-icon .bar.open:nth-child(2) {
    opacity: 0;
  }
  
  .menu-icon .bar.open:nth-child(3) {
    transform: rotate(45deg) translate(-5px, -6px);
  }
}
@media screen and (max-width: 1000px){

  .headerWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
 
    background-color: rgba(255, 255, 255, 0.804);

    height: 100px;
    width: auto;
  
    position: sticky;
    top: 0;
    z-index: 10;
  }
  .headerWrapper .img {
    height: 200px;
    width: 200px;
  }
  .headerWrapper .nav ul {
    display: flex;
    flex-direction: row;
    gap: 30px;
  }
  
  .headerWrapper .nav ul a {
    text-decoration: none;
    color: #391710;
  }
  .headerWrapper .nav ul li {
    list-style: none;
    font-weight: bold;
    font-size: 22px;
  }
  .headerWrapper .nav ul li:hover {
    border-bottom: 4px solid #d9b53b;
    border-radius: 5px;
    padding-bottom: 5px;
  }
  
  .headerWrapper .icon {
    display: flex;
    flex-direction: row;
    gap: 10px;
  }
  .menu-icon {
    display: none; 
    cursor: pointer;
  }
  .menu-icon div {
    width: 25px;
    height: 3px;
    background-color:#00322f;
    margin: 6px 0;
    transition: 0.4s;
  }
  
  .menu-icon .bar.open:nth-child(1) {
    transform: rotate(-45deg) translate(-5px, 6px);
  }
  
  .menu-icon .bar.open:nth-child(2) {
    opacity: 0;
  }
  
  .menu-icon .bar.open:nth-child(3) {
    transform: rotate(45deg) translate(-5px, -6px);
  }
}
@media screen and (max-width: 900px){
  .headerWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
 
    background-color: rgba(255, 255, 255, 0.804);
   
    height: 100px;
    width: auto;
  
    position: sticky;
    top: 0;
    z-index: 10;
  }
 
  .headerWrapper .img {
    height: 200px;
    width: 200px;
  }
  .menu-icon {
    display: block;
   position: relative;
   right: 0px;
     
  
  }
  .menu-icon div {
    width: 25px;
    height: 3px;
    background-color:#01180f;
    margin: 6px 0;
    transition: 0.4s;
  }
  .headerWrapper .nav ul {
    display: none;
   
  }
 
  .headerWrapper .navOpen ul {
    display: flex;
    flex-direction: column;
    background-color: rgba(255, 255, 255, 0.877);
   gap: 50px;
    height: 400px;
    width: 100%;
    position: fixed;
    right: 0px;
    top: 83px;
    z-index: 999;

  
   
  }
  .headerWrapper .navOpen ul a {
    text-decoration: none;
    color: #391710;
  }
  .headerWrapper .navOpen ul li {
    list-style: none;
    font-weight: bold;
    font-size: 26px;
  }
  .headerWrapper .navOpen ul li:hover {
   color: #8e622e;
    border-radius: 5px;
    padding-bottom: 5px;
  }
  .headerWrapper .icon {
    display: none;
  
  }
 
}
@media screen and (max-width: 768px) {
  .headerWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0px 10px;
    background-color: rgba(255, 255, 255, 0.804);
   
    height: 70px;
    width: auto;
  
    position: sticky;
    top: 0;
    z-index: 10;
  }
 
  .headerWrapper .img {
    height: 150px;
    width: 150px;
  }
  .menu-icon {
    display: block;
   position: relative;
   right: 0px;
     
  
  }
  .headerWrapper .nav ul {
    display: none;
   
  }
 
  .headerWrapper .navOpen ul {
    display: flex;
    flex-direction: column;
    background-color: rgba(255, 255, 255, 0.877);
   gap: 50px;
    height: 400px;
    width: 100%;
    position: fixed;
    right: 0px;
    top: 54px;
    z-index: 999;
    font-family: "Cinzel", serif;

  
   
  }
  .headerWrapper .navOpen ul a {
    text-decoration: none;
    color: #391710;
  }
  .headerWrapper .navOpen ul li {
    list-style: none;
    font-weight: bold;
    font-size: 26px;
  }
  .headerWrapper .navOpen ul li:hover {
   
    border-radius: 5px;
    padding-bottom: 5px;
  }
  .headerWrapper .icon {
    display: none;
    
  }
}





@media screen and (max-width: 600px) {
  .headerWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0px 10px;
    background-color: rgba(255, 255, 255, 0);
   
    height: 70px;
    width: auto;
  
    position: sticky;
    top: 0;
    z-index: 10;
  }
 
  .headerWrapper .img {
   display: none;
  }
  .menu-icon {
    display: block;
   position: relative;
  
   right: 0px;
     
  
  }
  .menu-icon div {
    width: 25px;
    height: 3px;
    background-color:#f7c587;
    margin: 6px 0;
    transition: 0.4s;
  }
  .headerWrapper .nav ul {
    display: none;
   
  }
 
  .headerWrapper .navOpen ul {
    display: flex;
    flex-direction: column;
    background-color: rgba(255, 255, 255, 0.84);
   gap: 70px;
    height: 100vh;
    width: 70%;
    
    position: fixed;
    padding-top: 20px;
    right: 0px;
    top: 50px;
    z-index: 999;
  

  
   
  }
  .headerWrapper .navOpen ul a {
    text-decoration: none;
    color: #391710;
  }
  .headerWrapper .navOpen ul li {
    list-style: none;
    font-weight: bold;
    font-size: 22px;
  }
  .headerWrapper .navOpen ul li:hover {
    
    border-radius: 5px;
    padding-bottom: 5px;
  }
  .headerWrapper .icon {
    display: none;
    
  }
}


