.EncontWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  color: #391710;
  margin-top: 300px;

  height: 100%;
  width: 100%;
}
.boxwrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.boxw {
  display: flex;
  flex-direction: row;
  padding: 20px;
}
.boxw div {
  font-size: 19px;
 
}
.box {
  height: 20px;
  width: 20px;
  background-color: red;
  margin-right: 10px;
}
.box1 {
  height: 20px;
  width: 20px;
  background-color: rgb(30, 102, 21);
  margin-right: 10px;
}
.calenderWrapper {
  display: flex;
  flex-direction: column;
  width: 300px;
}
abbr {
  display: none;
}

.custom-tile {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  background-color: inherit;

  cursor: pointer;
  padding: 5px;
  margin: 0;
}

.custom-tile[aria-disabled="true"] {
  background-color: #e0e0e0;
  color: #888;
  cursor: not-allowed;
}
.formWrapper {
  height: 590px;
  width: 500px;
  background-color: white;
  border-radius: 30px;
  box-shadow: 2px 3px 10px rgb(183, 182, 182);
}
.formWrapper .headingEn {
  font-size: 30px;
  font-weight: bold;
  color: #391710;
  padding: 10px 0px;
}
.formWrapper .label {
  padding: 7px 0px;
}
.formWrapper label {
  font-size: 19px;

 
  color: #391710;
}
.formWrapper input {
  width: 60%;
  padding: 0px 20px;
  height: 30px;
  border-radius: 10px;
}
.formWrapper select {
  width: 70%;
  padding: 0px 20px;
  height: 35px;
  border-radius: 10px;
}
.formWrapper .button {
  padding: 30px;
}
.formWrapper button {
  padding: 10px 50px;
  background-color: #391710;
  border-radius: 5px;
  border: #391710;
  color: white;
}
@media screen and (max-width:900px){
  .EncontWrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    color: #391710;
    margin-top: 181px;
    height: 100%;
    width: 100%;
    align-items: center;
}
  
} 
@media screen and (max-width:600px){
  .EncontWrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    color: #391710;
    margin-top: 174px;
    height: 100%;
    width: 100%;
}
  .boxwrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  .boxw {
    display: flex;
    flex-direction: row;
    padding: 20px;
  }
  .boxw div {
    font-size: 20px;
   
}
  .box {
    height: 15px;
    width: 15px;
    background-color: red;
    margin-right: 10px;
  }
  .box1 {
    height: 15px;
    width: 15px;
    background-color: rgb(30, 102, 21);
    margin-right: 10px;
  }
  .calenderWrapper {
    display: flex;
    flex-direction: column;
    width: 300px;
  }
  abbr {
    display: none;
  }
  
  .custom-tile {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    background-color: inherit;
  
    cursor: pointer;
    padding: 5px;
    margin: 0;
  }
  
  .custom-tile[aria-disabled="true"] {
    background-color: #e0e0e0;
    color: #888;
    cursor: not-allowed;
  }
  .formWrapper {
    height: 489px;
    width: 307px;
    background-color: white;
    border-radius: 30px;
    box-shadow: 2px 3px 10px rgb(183, 182, 182);
}
.formWrapper .headingEn {
  font-size: 28px;
  font-weight: bold;
  color: #391710;
  padding: 7px 0px;
}
  .formWrapper .label {
    padding: 7px 0px;
  }
  .formWrapper label {
    font-size: 14px;
 
    color: #391710;
}
  .formWrapper input {
    width: 62%;
    padding: 0px 20px;
    height: 26px;
    border-radius: 3px;
}
  .formWrapper select {
    width: 70%;
    padding: 0px 20px;
    height: 35px;
    border-radius: 10px;
  }
  .formWrapper .button {
    padding: 30px;
  }
  .formWrapper button {
    padding: 10px 50px;
    background-color: #391710;
    border-radius: 5px;
    border: #391710;
    color: white;
  }
}