.swiper {
  width: 100%;
  height: 100%;
}

.custom-slide {
  text-align: center;
  font-size: 18px;
  background-color: #f5e9cd;
  display: flex;
  justify-content: center;
  align-items: center;
}

.custom-slide img {
  display: block;
  width: 100%;
  height: 100vh;
  object-fit: cover;
}
.slide-content {
  position: relative;
}

@media screen and (min-width: 550px) and (max-width: 600px) {
  .custom-slide {
    /* text-align: center;
    font-size: 18px;
    background-color: #f5e9cd;
    display: flex;
    justify-content: center;
    align-items: center; */
    display: none;
  }

  .custom-slide img {
    display: block;
    width: 100%;
    height: 75vh;
    object-fit: cover;
  }
  .slide-content {
    position: relative;
  }
}
@media screen and (min-width: 425px) and (max-width: 550px) {
  .custom-slide {
    /* text-align: center;
    font-size: 18px;
    background-color: #f5e9cd;
    display: flex;
    justify-content: center;
    align-items: center; */
    display: none;
  }

  /* .custom-slide img {
    display: block;
    width: 100%;
    height: 69vh;
    object-fit: cover;
  }
  .slide-content {
    position: relative;
  } */
}
@media screen and (max-width: 425px) {
  .custom-slide {
    /* text-align: center;
    /* font-size: 12px; */
    /* background-color: #f5e9cd;
    display: flex;
    justify-content: center;
    align-items: center;  */
    display: none;
  }

  /* .custom-slide img {
    display: block;
    width: 100%;
    height: 66vh;
    object-fit: cover;
  } */
}
@media screen and (max-width: 320px) {
  .custom-slide {
    /* text-align: center;
  
    background-color: #f5e9cd;
    display: flex;
    justify-content: center;
    align-items: center; */
  }

  /* .custom-slide img {
    display: block;
    width: 100%;
    height: 56vh;
    object-fit: cover;
  } */
}
