.pserWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding-top: 50px;
}
.mainser{
  display: flex;
  flex-direction: column;
}
.serheading {
  color: #391710;
  font-size: 35px;
  font-weight: bold;
  word-spacing: 3px;
  font-family: "Cinzel", serif;
  display: inline;
}
.mobile-v{
  display: none;
}
.serheadwrap {
  padding-top: 40px;
}

.timepass {
  height: 395px;
  width: 313px;
  background-color: rgb(255, 255, 255);
  position: relative;
  overflow: hidden;
  border-radius: 0px;
 
}
.timepass h1 {
  display: none;
  position: absolute;
  top: 20px;
  left: 0;
  width: 100%;
  height: 120px;
  margin: 0;
  padding: 10px;
  box-sizing: border-box;
  color: #391710;
  text-align: center;
  font-size: 25px;
  font-family: "Cinzel", serif;
 
}
.timepass img {
  width: 100%;
  height: 100%;
  border-radius: 0px;
  transition: padding 0.9s ease;
}

.timepass:hover img {
  padding-top: 30%;
}
.timepass:hover h1 {
  display: block;
}
.serimgwrap {
  width: 800px;
  height: 400px;
  /* border-radius: 20px;
  border: 2px solid #f8d556e5; */
}
.sercont {
  font-size: 30px;
  padding: 48px 14px;
  text-align: center;
  line-height: 50px;
}
@media screen and (max-width:950px){
  .mainser{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
   
   
  }
  .pserWrapper {
    display: flex;
    flex-direction: column;
    gap: 57px;
    justify-content: space-around;
    align-items: center;
    padding-top: 47px;
  
  }
  .serheading {
    color: #391710;
    font-size: 30px;
    font-weight: bold;
    word-spacing: 3px;
   
    display: inline;
  }

  .serheadwrap {
    padding-top: 40px;
    padding-bottom: 30px;
  }
  
  .timepass {
    height: 250px;
    width: 230px;
    background-color: rgb(255, 255, 255);
    position: relative;
    overflow: hidden;
  
    box-shadow: 2px 3px 8px rgb(105, 104, 104);
   
  }
  .timepass h1 {
    display: none;
    position: absolute;
    top: 10px;
    left: 0;
    width: 100%;
    height: 120px;
    margin: 0;
    padding: 10px;
    box-sizing: border-box;
    color: #391710;
    text-align: center;
    font-size: 20px;
    
    font-weight: bold;
  }
  .timepass img {
    width: 100%;
    height: 100%;
   
    transition: padding 0.9s ease;
  }
 
  .timepass:hover img {
    padding-top: 30%;
  }
  .timepass:hover h1 {
    display: block;
  }
  .serimgwrap {
    width: 598px;
    height: 317px;
 
}
  .sercont {
    font-size: 21px;
    padding: 48px 11px;
    line-height: 50px;
  }
}
@media screen and (max-width:650px){
  .mainser{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    
   
  }
  .pserWrapper {
    display: flex;
    flex-direction: column;
    gap: 57px;
    justify-content: space-around;
    align-items: center;
    padding-top: 47px;
    
  }
  .serheading {
    color: #391710;
    font-size: 25px;
    font-weight: bold;
    word-spacing: 3px;
   
    display: inline;
  }
 
  .serheadwrap {
    padding-top: 40px;
    padding-bottom: 60px;
  }
  
  .timepass {
    height: 254px;
    width: 217px;
    background-color: rgb(255, 255, 255);
    position: relative;
    overflow: hidden;
   
    box-shadow: 2px 3px 8px rgb(105, 104, 104);
}
  .timepass h1 {
    display: none;
    position: absolute;
    top: 10px;
    left: 0;
    width: 100%;
    height: 120px;
    margin: 0;
    padding: 10px;
    box-sizing: border-box;
    color: #391710;
    text-align: center;
    font-size: 18px;
    
    font-weight: bold;
  }
  .timepass img {
    width: 100%;
    height: 100%;
    
    transition: padding 0.9s ease;
  }

  .timepass:hover img {
    padding-top: 30%;
  }
  .timepass:hover h1 {
    display: block;
  }
  .serimgwrap {
    width: 454px;
    height: 272px;
  
}
  .sercont {
    font-size: 21px;
    padding: 48px 11px;
    line-height: 50px;
  }
}

@media screen and (max-width:520px){
  .mainser{
   
    display: none;
   
  }
  .pserWrapper {
   
   display: none;
  }
  .serheading {
    color: #391710;
    font-size: 20px;
    font-weight: bold;
    word-spacing: 3px;
   
    display: inline;
  }
  .mobile-v{
    display: block;
  }
  .serheadwrap {
    padding-top: 40px;
    padding-bottom: 60px;
  }
  .swiper_Slide{
    background-position: center;
    background-size: cover;
    background-color: rgba(255, 255, 255, 0.882);
    width: 194px;
    height: 241px;
    border-radius: 10px;

  }
  .sermg{
    display: block;
    width: 194px;
    height: 241px;
    position: relative;

  }
  .ser{
    position: absolute;
    color: white;
    font-size: 20px;
    background-color:#24242466; ;
    width: 150px;
    bottom: 50%;
    left: 10%;
    right: 10%;
    border-top: 1px solid white;
    border-bottom: 1px solid white;
  }
  
 
  .serimgwrap {
    width: 271px;
    height: 192px;
  
}
.sercont {
  font-size: 15px;
  padding: 27px 10px;
  line-height: 29px;
}
}

